<template>
    <article>
        <section class="home">
            <div class="container d-flex justify-content-center align-items-center">
                <div class="glows glow-1 wow fadeInLeft" >
                    <img src="../../assets/campaign/ultra-face-360/home_glow_1.png" alt="Glow">
                </div>
                <div class="model">
                    <img class="wow fadeIn" data-wow-delay="500ms" src="../../assets/campaign/ultra-face-360/home_model.png" alt="Model">
                    <div class="glows glow-2">
                        <img class="wow fadeInLeft" src="../../assets/campaign/ultra-face-360/home_glow_2.png" alt="Glow">
                    </div>
                </div>
                <div class="row headlines">
                    <div class="wrapper">
                        <h1 class="display-4 title mb-5 wow fadeInUp">
                            <span>無痛超頻V臉</span>
                            <sub>3.0</sub>
                        </h1>
                        <div class="advantages mb-2">
                            <h3 class="wow fadeIn">51% 膠原蛋白</h3>
                            <h4 class="wow fadeInUp">UP↑</h4>
                        </div>
                        <div class="advantages mb-2">
                            <h3 class="wow fadeIn">35% 緊緻度</h3>
                            <h4 class="wow fadeInUp">UP↑</h4>
                        </div>
                        <div class="advantages mb-4">
                            <h3 class="wow fadeIn">19% 肌膚密度</h3>
                            <h4 class="wow fadeInUp">UP↑</h4>
                        </div>
                        <h4 class="mb-1 wow fadeInUp">
                            限量半價體驗：
                        </h4>
                        <h1 class="display-4 price wow fadeInUp">
                            $2,150
                            <del>原價：$4,300</del>
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="intro d-flex justify-content-center align-items-center">
            <div class="background" id="particles"></div>
            <div class="container d-flex justify-content-center align-items-center">
                <div class="introductory-text">
                    <h1 class="text-center title font-weight-bold font-noto-sans mb-5"
                        data-aos="fade">
                        Generation V
                    </h1>
                    <h5 class="text-center" data-aos="fade-up">
                        女性最真實的美麗從未真正呈現在這個修圖年代。
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade-up">
                        漸覺雙眼變小、嘴角下垂、下巴與頸部界線難分…
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade-up">
                        我們追求於鏡頭前後一種獨一無二，完美一致的自信美麗。
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade-up">
                        而今天，我們昂然踏進一個美麗、無痛、輕易的V世代。
                    </h5>
                </div>
            </div>
        </section>

        <section class="features">
            <div class="container d-flex justify-content-center align-items-start">
                <div class="model">
                    <img src="../../assets/campaign/ultra-face-360/features_model.png" alt="model"
                         data-aos="fade">
                    <img id="face-effect" src="../../assets/campaign/ultra-face-360/features_face_effect.png" alt="face-effect">

                    <div class="feature-spot eye"
                         data-aos="fade"
                         data-aos-delay="250">
                        <svg class="d-block" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" xml:space="preserve">
                            <path fill="#00CCFF" d="M300,150c0,82.843-67.157,150-150,150S0,232.843,0,150S67.157,0,150,0S300,67.157,300,150z M150.427,50.298c-55.229,0-100,44.771-100,100s44.771,100,100,100c55.228,0,100-44.771,100-100S205.654,50.298,150.427,50.298z"></path>
                        </svg>
                    </div>
                    <div class="feature-item eye"
                         data-aos="fade"
                         data-aos-delay="550">
                        <h3>緊緻亮眼</h3>
                        <h5>提升眼部輪廓‧減淡眼周紋理‧淡化黑眼圈‧消除眼部浮腫</h5>
                    </div>

                    <div class="feature-spot face"
                         data-aos="fade"
                         data-aos-delay="250">
                        <svg class="d-block" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" xml:space="preserve">
                    <path fill="#00CCFF" d="M300,150c0,82.843-67.157,150-150,150S0,232.843,0,150S67.157,0,150,0S300,67.157,300,150z M150.427,50.298c-55.229,0-100,44.771-100,100s44.771,100,100,100c55.228,0,100-44.771,100-100S205.654,50.298,150.427,50.298z"></path>
                </svg>
                    </div>
                    <div class="feature-item face"
                         data-aos="fade"
                         data-aos-delay="550">
                        <h3>提升 V 臉輪廓</h3>
                        <h5>減淡法令紋‧撫平皺紋和細紋</h5>
                    </div>

                    <div class="feature-spot cheek"
                         data-aos="fade"
                         data-aos-delay="250">
                        <svg class="feature-spot line d-block" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" xml:space="preserve">
                            <path fill="#00CCFF" d="M300,150c0,82.843-67.157,150-150,150S0,232.843,0,150S67.157,0,150,0S300,67.157,300,150z M150.427,50.298c-55.229,0-100,44.771-100,100s44.771,100,100,100c55.228,0,100-44.771,100-100S205.654,50.298,150.427,50.298z"></path>
                        </svg>
                    </div>
                    <div class="feature-item cheek"
                         data-aos="fade"
                         data-aos-delay="550">
                        <h3>提升下巴線條</h3>
                        <h5>擊退雙下巴</h5>
                    </div>

                    <div class="feature-spot neck"
                         data-aos="fade"
                         data-aos-delay="250ms">
                        <svg class="d-block" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" xml:space="preserve">
                    <path fill="#00CCFF" d="M300,150c0,82.843-67.157,150-150,150S0,232.843,0,150S67.157,0,150,0S300,67.157,300,150z M150.427,50.298c-55.229,0-100,44.771-100,100s44.771,100,100,100c55.228,0,100-44.771,100-100S205.654,50.298,150.427,50.298z"></path>
                </svg>
                    </div>
                    <div class="feature-item neck"
                         data-aos="fade"
                         data-aos-delay="550ms">
                        <h3>減淡頸紋</h3>
                        <h5></h5>
                    </div>
                </div>
                <div class="row headlines"
                     data-aos="fade">
                    <div class="wrapper title">
                        <h1>ULTRA FACE 360重塑你的完美角度</h1>
                        <h1>緊緻提升四大部位</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="feature-items">
            <div class="container wow fadeIn">
                <div class="title text-center pt-5 mb-5">
                    <h1>ULTRA FACE 360重塑你的完美角度</h1>
                    <h1>緊緻提升四大部位</h1>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center pb-5 mb-0">
                    <h3 class="title">緊緻亮眼</h3>
                    <ul class="mt-2 mb-4">
                        <li>
                            <h5>提升眼部輪廓</h5>
                        </li>
                        <li>
                            <h5>減淡眼周紋理</h5>
                        </li>
                        <li>
                            <h5>淡化黑眼圈</h5>
                        </li>
                        <li>
                            <h5>消除眼部浮腫</h5>
                        </li>
                    </ul>
                    <h3 class="title">提升V臉輪廓</h3>
                    <ul class="mt-2 mb-4">
                        <li>
                            <h5>減淡法令紋</h5>
                        </li>
                        <li>
                            <h5>撫平皺紋和細紋</h5>
                        </li>
                    </ul>
                    <h3 class="title">提升下巴線條</h3>
                    <ul class="mt-2 mb-4">
                        <li>
                            <h5>擊退雙下巴</h5>
                        </li>
                    </ul>
                    <h3 class="title">減淡頸紋</h3>
                </div>
            </div>
        </section>

        <section class="methods">
            <div class="container d-flex flex-column justify-content-center align-items-center pt-4">
                <h1 class="text-center title font-weight-bold my-5 wow fadeIn">ULTRA FACE 360與其他技術比拼</h1>
                <div class="cards row justify-content-center pt-4 pb-5">
                    <div class="col-lg row mb-3">
                        <div class="col-md card mb-3 mb-md-0 mr-md-3 p-0 wow fadeInUp">
                            <div class="card-body">
                                <div class="card-title text-center featured d-flex flex-column justify-content-center py-2">
                                    <h4 class="mb-0">ULTRA FACE 360</h4>
                                    <h4 class="mb-0">緊膚療程</h4>
                                </div>
                                <div class="card-text">
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            痛楚度
                                        </div>
                                        <div class="col-8 mt-2">
                                            <p>0級 無需麻醉</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            持久度
                                        </div>
                                        <div class="col-8 mt-2">
                                            <p>持久度達一年以上*，且即時緊實線條，提升輪廓，最佳效果於3個月後漸現</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            深入度
                                        </div>
                                        <div class="col-8 mt-2">
                                            <p>能量穿透力更強大，直抵膠原層底部，激活膠原的區域更深、更廣、更均勻</p>
                                        </div>
                                    </div>
                                    <div class="image row d-flex justify-content-center align-items-center">
                                        <img class="img-fluid p-3" src="../../assets/campaign/ultra-face-360/method_collagen_xp.png" alt="ULTRA FACE 360">
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            安全度
                                        </div>
                                        <div class="col-8 mt-2">
                                            <p>獲美國FDA認可及歐盟CE認證，安全及效果經醫學臨床測試</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md card mr-md-3 p-0 wow fadeInUp">
                            <div class="card-body">
                                <div class="card-title text-center d-flex flex-column justify-content-center py-2">
                                    <h4 class="mb-0">高聚焦超聲波</h4>
                                    <h4 class="mb-0">HIFU</h4>
                                </div>
                                <div class="card-text">
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            痛楚度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>0級 無需麻醉</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            持久度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>或會即時緊緻，持久度較射頻高</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            深入度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>能量傳送不均勻，只能於1-3個皮膚層分段式加熱</p>
                                        </div>
                                    </div>
                                    <div class="image row d-flex justify-content-center align-items-center">
                                        <img class="img-fluid p-3" src="../../assets/campaign/ultra-face-360/method_hifu.png" alt="HIFU">
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            安全度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>部分品牌只獲得韓國KFDA認可，而非美國FDA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg row mb-3">
                        <div class="col-md card mb-3 mb-md-0 mr-md-3 p-0 wow fadeInUp">
                            <div class="card-body">
                                <div class="card-title text-center d-flex flex-column justify-content-center py-2">
                                    <h4 class="mb-0" style="white-space: nowrap;">雙極/多極射頻</h4>
                                    <h4 class="mb-0">Bipolar / Multipolar RF</h4>
                                </div>
                                <div class="card-text">
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            痛楚度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>3級 麻醉視乎情況</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            持久度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>短暫緊緻，持久度成疑</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            深入度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>未能深入，且能量會隨深入度而減弱，影響成效</p>
                                        </div>
                                    </div>
                                    <div class="image row d-flex justify-content-center align-items-center">
                                        <img class="img-fluid p-3" src="../../assets/campaign/ultra-face-360/method_multipolar_rf.png" alt="Multipolar RF">
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            安全度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>有機會灼傷表皮，進行時或需表皮冷卻</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md card mr-md-3 p-0 wow fadeInUp">
                            <div class="card-body">
                                <div class="card-title text-center d-flex flex-column justify-content-center py-2">
                                    <h4 class="mb-0">單極射頻</h4>
                                </div>
                                <div class="card-text">
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            痛楚度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>5級 需要麻醉</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            持久度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>一些療程只短暫緊緻，亦有部分療程較為持久</p>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            深入度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>可深入真皮層及皮下組織，但非針對性</p>

                                        </div>
                                    </div>
                                    <div class="image row d-flex justify-content-center align-items-center">
                                        <img class="img-fluid p-3" src="../../assets/campaign/ultra-face-360/method_unipolar_rf.png" alt="Unipolar RF">
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 header text-center px-0 mt-2">
                                            安全度
                                        </div>
                                        <div class="col-8 text-justify mt-2">
                                            <p>能量偏高，有機會灼傷表皮，進行時需表皮冷卻</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="comparison-1">
            <div class="container py-5">
                <h1 class="text-center title font-weight-bold mb-5 wow fadeIn">ULTRA FACE 360臨床實證</h1>
                <h5 class="mb-4 wow fadeInUp">緊緻亮眼</h5>
                <div class="row wow fadeIn">
                    <div class="col-md-6">
                        <h6>提升眼瞼、減淡魚尾紋與皺紋</h6>
                        <img class="img-fluid" src="../../assets/campaign/ultra-face-360/compare1_before_after_1.png">
                        <div class="d-flex image-before-after">
                            <div class="col">
                                <p>治療前</p>
                            </div>
                            <div class="col">
                                <p>2次治療後</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h6>緊緻眼周、撫平眼袋、提亮眼眸</h6>
                        <img class="img-fluid" src="../../assets/campaign/ultra-face-360/compare1_before_after_2.png">
                        <div class="d-flex image-before-after">
                            <div class="col">
                                <p>治療前</p>
                            </div>
                            <div class="col">
                                <p>2次治療後 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="mt-4 mb-0 pb-0">照片由Dr. Nicole A. Hartsough提供</p>
                <p class="mt-0 mb-0">圖片只供參考 療程效果因人而異</p>
            </div>
        </section>

        <section class="comparison-2">
            <div class="container py-5">
                <h5 class="mb-5 wow fadeInUp">提升V臉輪廓、下巴線條和頸紋</h5>
                <div class="row wow fadeIn">
                    <div class="col-md-6">
                        <h6>改善雙下巴、緊緻下顎輪廓</h6>
                        <img class="img-fluid" src="../../assets/campaign/ultra-face-360/compare2_before_after_1.png">
                        <div class="d-flex image-before-after">
                            <div class="col">
                                <p>治療前</p>
                            </div>
                            <div class="col">
                                <p>4次治療後</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h6>提升輪廓，減淡法令紋</h6>
                        <img class="img-fluid" src="../../assets/campaign/ultra-face-360/compare2_before_after_2.png">
                        <div class="d-flex image-before-after">
                            <div class="col">
                                <p>治療前</p>
                            </div>
                            <div class="col">
                                <p>4次治療後</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="mt-4 mb-0 pb-0">照片由Dr. Robert Weiss和Dr. Grant Stevens提供</p>
                <p class="mt-0 mb-0">圖片只供參考 療程效果因人而異</p>
            </div>
        </section>

        <enquiry duplicate-to-g-sheet
                 v-bind:g-sheet-id="gSheetIds"
                 g-sheet-id-key="branch"
                 v-bind:treatment-id="treatmentId">
            免費專業美容顧問咨詢 及 安排首次半價體驗
        </enquiry>
    </article>
</template>

<script>
    import Enquiry from "./Enquiry";
    export default {
        name: "UltraFace360",
        components: {
            Enquiry
        },
        metaInfo () {
            return {
                title: this.campaign.title[this.$i18n.locale],
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.campaign.title[this.$i18n.locale],
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
            }
        },
        props: {
            treatmentId: {
                type: String,
                required: true,
            }
        },
        data () {
            return {
                gSheetIds: {
                    "香港銅鑼灣羅素街38號金朝陽中心9樓902-903室": "AKfycbx5tef8q9huChgYmK5bLO65mCL_HEfbBQMzN8HC94LVOTXxcAfe", // CWB
                    "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
                },
            }
        },
        computed: {
            treatment () {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {};
            },
        },
    }
</script>

<style lang="scss" scoped>
    @mixin sm {
        @media (min-width: 576px) {
            @content;
        }
    }

    @mixin md {
        @media (min-width: 768px) {
            @content;
        }
    }

    @mixin lg {
        @media (min-width: 992px) {
            @content;
        }
    }

    @mixin xl {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @mixin sm-max {
        @media (max-width: 575px) {
            @content;
        }
    }

    @mixin md-max {
        @media (max-width: 767px) {
            @content;
        }
    }

    @mixin lg-max {
        @media (max-width: 991px) {
            @content;
        }
    }

    @mixin xl-max {
        @media (max-width: 1199px) {
            @content;
        }
    }

    section {
        &.home {
            height: 100vh;

            background-image: url("../../assets/campaign/ultra-face-360/landing1_home_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center left;

            & > .container {
                position: relative;
                height: 100%;

                .glows {
                    position: absolute;
                    bottom: 0;
                    height: 100%;
                    user-select: none;

                    img {
                        height: 100%;
                        width: auto;
                        max-width: 500px;
                    }

                    &.glow-1 {
                        left: -20%;
                    }

                    &.glow-2 {
                        right: -45px;
                    }

                    @include lg {
                        &.glow-1 {
                            left: 0;
                        }
                    }
                }

                .model {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    user-select: none;
                    overflow: hidden;

                    & > img {
                        height: 100vh;
                        width: auto;
                    }

                    @include lg {
                        left: 0;
                        right: unset;
                    }
                }

                .headlines {
                    width: 100%;
                    margin-top: 0;
                    z-index: 9;

                    @include lg {
                        margin-top: 20vh;
                    }

                    & > .wrapper {
                        margin-left: auto;

                        h3, h4 {
                            font-weight: 300;
                        }

                        & > h1 {
                            &.display-4 {
                                position: relative;
                                white-space: nowrap;
                                font-weight: 500;

                                &.title {
                                    font-size: 46px;
                                    text-align: right;

                                    @include lg {
                                        font-size: 56px;
                                        text-align: unset;
                                    }

                                    & > span {
                                        text-orientation: upright;
                                        writing-mode: vertical-rl;

                                        @include lg {
                                            text-orientation: unset;
                                            writing-mode: unset;
                                        }
                                    }

                                    & > sub {
                                        vertical-align: bottom;
                                        font-size: 60%;
                                        letter-spacing: normal;

                                        position: absolute;
                                        right: -0.5rem;

                                        @include lg {
                                            position: relative;
                                            right: unset;
                                        }
                                    }
                                }

                                &.price {
                                    letter-spacing: 0.35rem;

                                    del {
                                        position: absolute;
                                        letter-spacing: normal;
                                        bottom: -25px;
                                        left: 0;
                                        font-size: 40%;

                                        @include lg {
                                            left: 90px;
                                        }
                                    }
                                }

                                span {
                                    letter-spacing: 0.75rem;
                                }
                            }
                        }

                        & > .advantages {
                            display: none;

                            & > h3 {
                                display: inline-block;
                            }

                            & > h4 {
                                display: inline-block;
                                border: solid 1px rgba(94, 94, 94, 1);
                                border-radius: 2px;

                                padding: 0 7px;
                                font-size: 18px;
                                vertical-align: top;
                            }

                            @include lg {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.intro {
            position: relative;
            min-height: 500px;

            & > .background {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            & > .container {
                z-index: 9;

                .introductory-text {
                    & > h1 {
                        font-size: 2.75rem;
                        letter-spacing: 0.25rem;
                    }

                    & > h5 {
                        font-weight: 300;
                    }
                }
            }
        }

        &.features {
            height: 100vh;

            background-image: url("../../assets/campaign/ultra-face-360/landing1_features_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            .container {
                position: relative;
                height: 100%;
                overflow: hidden;

                .model {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    user-select: none;

                    @include lg {
                        left: unset;
                        right: 0;
                    }

                    & > img {
                        height: 100vh;
                        width: auto;

                        &#face-effect {
                            position: absolute;
                            top: 33vh;
                            right: 33%;
                            height: 50vh;
                            user-select: none;


                        }
                    }

                    & > .feature-spot {
                        position: absolute;
                        height: 1.5%;
                        width: auto;
                        user-select: none;

                        & > svg {
                            height: 100%;
                            width: auto;
                        }

                        &.eye {
                            top: 42.25%;
                            left: 30%;
                        }

                        &.face {
                            top: 54.25%;
                            left: 26%;
                        }

                        &.cheek {
                            top: 66.25%;
                            left: 19%;
                        }

                        &.neck {
                            top: 76.25%;
                            left: 38%;
                        }
                    }

                    & > .feature-item {
                        display: none;
                        position: absolute;
                        left: calc((100vw - 100% - 10%) * -1);
                        user-select: none;

                        @include lg {
                            display: block;
                        }

                        @include sm {
                            left: calc((540px - 100% - 10%) * -1);
                        }

                        @include md {
                            left: calc((720px - 100% - 10%) * -1);
                        }

                        @include lg {
                            left: calc((960px - 100% - 10%) * -1);
                        }

                        @include xl {
                            left: calc((1140px - 100% - 10%) * -1);
                        }

                        h3 {
                            border-bottom: solid 3px rgba(0, 204, 255, 1);
                            padding-bottom: 3px;
                            margin-bottom: 0;
                        }

                        h5 {
                            padding-left: 10px;
                        }

                        &.eye {
                            top: 39%;
                            width: calc(100vw - 100% + 30% - 10%);
                        }

                        &.face {
                            top: 51%;
                            width: calc(100vw - 100% + 26% - 10%);
                        }

                        &.cheek {
                            top: 63%;
                            width: calc(100vw - 100% + 19% - 10%);
                        }

                        &.neck {
                            top: 73%;
                            width: calc(100vw - 100% + 38% - 10%);

                        }

                        @include sm {
                            &.eye {
                                width: calc(540px - 100% + 30% - 10%);
                            }

                            &.face {
                                width: calc(540px - 100% + 26% - 10%);
                            }

                            &.cheek {
                                width: calc(540px - 100% + 19% - 10%);
                            }

                            &.neck {
                                width: calc(540px - 100% + 38% - 10%);
                            }
                        }

                        @include md {
                            &.eye {
                                width: calc(720px - 100% + 30% - 10%);
                            }

                            &.face {
                                width: calc(720px - 100% + 26% - 10%);
                            }

                            &.cheek {
                                width: calc(720px - 100% + 19% - 10%);
                            }

                            &.neck {
                                width: calc(720px - 100% + 38% - 10%);
                            }
                        }

                        @include lg {
                            &.eye {
                                width: calc(960px - 100% + 30% - 10%);
                            }

                            &.face {
                                width: calc(960px - 100% + 26% - 10%);
                            }

                            &.cheek {
                                width: calc(960px - 100% + 19% - 10%);
                            }

                            &.neck {
                                width: calc(960px - 100% + 38% - 10%);
                            }
                        }

                        @include xl {
                            &.eye {
                                width: calc(1140px - 100% + 30% - 10%);
                            }

                            &.face {
                                width: calc(1140px - 100% + 26% - 10%);
                            }

                            &.cheek {
                                width: calc(1140px - 100% + 19% - 10%);
                            }

                            &.neck {
                                width: calc(1140px - 100% + 38% - 10%);
                            }
                        }
                    }
                }

                .headlines {
                    display: none;
                    width: 100%;
                    margin-top: 20vh;
                    z-index: 9;

                    & > .wrapper {
                        margin-right: auto;
                    }

                    @include lg {
                        display: block;
                    }
                }
            }
        }

        &.feature-items {
            display: block;
            background-color: rgba(225, 237, 247, 1);

            @include lg {
                display: none;
            }

            .d-flex {
                & > .title {
                    color: rgba(0, 204, 255, 1);
                }
            }
        }

        &.methods {
            min-height: 100vh;

            background-image: url("../../assets/campaign/ultra-face-360/landing1_methods_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            .cards {
                .card {
                    .card-body {
                        .card-title {
                            &.featured {
                                color: rgba(255, 255, 255, 1);
                                background-color: rgba(0, 204, 255, 1);
                            }
                        }

                        .card-text {
                            .row {
                                border-bottom: solid 1px rgba(201, 201, 201, 1);

                                &:nth-child(3),
                                &:last-child {
                                    border-bottom: none;
                                }

                                &:nth-child(1) {
                                    min-height: 40px;
                                }

                                &:nth-child(2) {
                                    min-height: 80px;
                                }

                                &:nth-child(3) {
                                    min-height: 80px;
                                }

                                &:nth-child(5) {
                                    min-height: 80px;
                                }

                                @include sm {
                                    &:nth-child(1) {
                                        min-height: 40px;
                                    }

                                    &:nth-child(2) {
                                        min-height: 80px;
                                    }

                                    &:nth-child(3) {
                                        min-height: 80px;
                                    }

                                    &:nth-child(5) {
                                        min-height: 80px;
                                    }
                                }

                                @include md {
                                    &:nth-child(1) {
                                        min-height: 40px;
                                    }

                                    &:nth-child(2) {
                                        min-height: 120px;
                                    }

                                    &:nth-child(3) {
                                        min-height: 80px;
                                    }

                                    &:nth-child(5) {
                                        min-height: 80px;
                                    }
                                }

                                @include lg {
                                    &:nth-child(1) {
                                        min-height: 40px;
                                    }

                                    &:nth-child(2) {
                                        min-height: 200px;
                                    }

                                    &:nth-child(3) {
                                        min-height: 120px;
                                    }

                                    &:nth-child(5) {
                                        min-height: 100px;
                                    }
                                }

                                @include xl {
                                    &:nth-child(1) {
                                        min-height: 80px;
                                    }

                                    &:nth-child(2) {
                                        min-height: 180px;
                                    }

                                    &:nth-child(3) {
                                        min-height: 200px;
                                    }

                                    &:nth-child(5) {
                                        min-height: 160px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.comparison-1 {
            background-image: url("../../assets/campaign/ultra-face-360/landing1_compare1_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            .container {
                & > h5 {
                    color: rgba(0, 204, 255, 1);
                }
            }
        }

        &.comparison-2 {
            background-image: url("../../assets/campaign/ultra-face-360/landing1_compare2_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            .container {
                & > h5 {
                    color: rgba(255, 255, 255, 1);
                }

                h6 {
                    color: rgba(255, 255, 255, 1);
                }

                p {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    @keyframes fadeLoop {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        70% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }


    .fadeLoop {
        animation-name: fadeLoop;
    }
</style>
